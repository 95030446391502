import React from 'react';
import styled from 'styled-components';
import {Button, Table, Tag} from 'antd';
import {navigate} from 'gatsby';

import * as AppContext from '../../AppContext';
import {
  LeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  Edit,
} from '../../Widgets/Icon';
import ActivityDetailDisplay from '../../Components/Activity.DisplayDetail';

const queryString = require('query-string');

function EventPage(props) {
  const app = React.useContext(AppContext.Context);
  const [activity, setActivity] = React.useState(null);
  const [participant, setParticipant] = React.useState(null);

  const fetchActivityById = React.useCallback(async () => {
    try {
      const params = queryString.parse(window.location.search);
      setActivity(await app.actions.fetchActivityById(params.activity));
    } catch (err) {
      throw err;
    }
  }, [app.actions]);

  const fetchParticipantById = React.useCallback(async () => {
    try {
      const params = queryString.parse(window.location.search);
      let resp = await app.actions.memberGetParticipantListByActivityId(
        params.activity,
      );
      let result = resp.find((r) => r.activity === params.activity);
      setParticipant(result.duration);
    } catch (err) {
      throw err;
    }
  }, [app.actions]);

  const onConfirm = async (id, data) => {
    try {
      let result = await app.actions.memberUpdateParticipantById(id, data);
      return result;
    } catch (err) {
      throw err;
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      app.actions.setLoading(true);
      await fetchActivityById();
      await fetchParticipantById();
      app.actions.setLoading(false);
    }

    fetchData();
  }, [app.actions, fetchActivityById, fetchParticipantById]);

  const columns = [
    {
      title: '報名日期',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '上班時段',
      dataIndex: 'duration',
      key: 'start',
      render: (_, record, index) => (
        <div>
          {record.time[0]}-{record.time[1]}
        </div>
      ),
    },
    {
      title: '狀態',
      dataIndex: 'state',
      key: 'state',
      render: (_, record, index) => {
        switch (record.state) {
          case 'applied':
            return (
              <div style={{marginBottom: 15, display: 'flex'}}>
                <Tag
                  icon={<SyncOutlined color="blue" size={12} />}
                  color="blue">
                  已報名
                </Tag>
              </div>
            );
          case 'qualified':
            return (
              <div style={{marginBottom: 15, display: 'flex'}}>
                <Tag
                  icon={<ClockCircleOutlined color="orange" size={12} />}
                  color="orange">
                  已錄取
                </Tag>
              </div>
            );
          case 'unqualified':
            return (
              <div style={{marginBottom: 15, display: 'flex'}}>
                <Tag
                  icon={<CloseCircleOutlined color="red" size={12} />}
                  color="red">
                  未錄取
                </Tag>
              </div>
            );
          case 'candidate':
            return (
              <div style={{marginBottom: 15, display: 'flex'}}>
                <Tag
                  icon={<MinusCircleOutlined color="purple" size={12} />}
                  color="purple">
                  備取
                </Tag>
              </div>
            );
          case 'confirmed':
            return (
              <div style={{marginBottom: 15, display: 'flex'}}>
                <Tag
                  icon={<CheckCircleOutlined color="green" size={12} />}
                  color="green">
                  已確認
                </Tag>
              </div>
            );
          case 'refused':
            return (
              <div style={{marginBottom: 15, display: 'flex'}}>
                <Tag icon={<CloseCircleOutlined size={12} />}>已婉拒</Tag>
              </div>
            );
          default:
            return `${record.state}`;
        }
      },
    },
    {
      title: '確認參加',
      dataIndex: 'confirm',
      key: 'confirm',
      render: (_, record, index) => {
        const params = queryString.parse(window.location.search);
        if (record.state === 'qualified') {
          return (
            <div>
              <Button
                onClick={async () => {
                  await onConfirm(params.participant, {
                    duration: [{date: record.date, time: record.time}],
                    confirm: true,
                  });
                  await fetchParticipantById();
                }}
                style={{marginRight: 20}}>
                確認
              </Button>
              <Button
                onClick={async () => {
                  await onConfirm(params.participant, {
                    duration: [{date: record.date, time: record.time}],
                    confirm: false,
                  });
                  await fetchParticipantById();
                }}>
                取消
              </Button>
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
    },
  ];

  return (
    <Wrapper>
      {activity && participant && (
        <>
          <div className="content">
            <div className="back">
              <Button
                shape="circle"
                onClick={() => {
                  navigate('/signup');
                }}>
                <LeftOutlined color="rgba(0, 0, 0, 0.65)" />
              </Button>
            </div>
            <ActivityDetailDisplay record={activity} showButton={false} />
          </div>

          <div className="content">
            <div className="header">
              <h3 className="title">報名詳情</h3>
              <span className="hint">提示：詳細上班時段請確認「班表」。</span>
            </div>
            <Table columns={columns} dataSource={participant} />
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;

  & > .content {
    max-width: 800px;
    margin: 60px auto;

    & > .back {
      position: absolute;
      top: 50px;
      left: 240px;
    }

    & > .header {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 20px;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 15px;

      & > .title {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 22px;
      }

      & > .hint {
        color: #ea0000;
      }
    }
  }
`;

export default EventPage;
